import React from "react"
import styled from "styled-components"
import kebabCase from "lodash/kebabCase"
import theme from "@assets/styles/theme"

import queryString from "query-string"

import AppLayout from "@components/layout/App"
import ProgressPrevNext from "@components/elements/ProgressPrevNext"
import ProgressArrowBox from "@components/elements/ProgressArrowBox"

import tileArrowNext from "@assets/jobs/tile-arrow-next.svg"

import HeaderBg from "@assets/career-levels/banner_career_band.jpg"
import HeaderBgMobile from "@assets/career-levels/banner_career_band_mobile.jpg"
import BulletGreen from "@assets/styles/bullet-green.svg"

import { DesktopContent } from "@components/elements/ResponsiveContent"
import { getBandDetailsUrlFromComparisonUrl } from "@helpers/url"
import { navigate } from "@reach/router"

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
`

const PAGE_HEADER_HEIGHT = "168px"
const PAGE_GREY_HEIGHT = "88px"

const PageHeader = styled.div`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  height: ${PAGE_HEADER_HEIGHT};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;

  @media (max-width: 1023px) {
    background-image: url(${HeaderBgMobile});
    height: auto;
  }
`

const PageHeaderBoxes = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderGrey = styled.div`
  background-color: rgba(63, 63, 63, 0.9);
  color: ${theme.colors.grey.white};
  flex: 1;
  height: ${PAGE_GREY_HEIGHT};

  overflow: hidden;

  @media (max-width: 1023px) {
    background-color: transparent;
    padding: 0;
  }
`

const PageHeaderBlocker = styled.div`
  background-color: transparent;
  width: 40%;
  height: 60px;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderTextContainer = styled.div`
  color: ${theme.colors.grey.white};
  width: min(1140px, calc(100vw - 32px));
  padding: 24px 0;
  z-index: 2;
  height: ${PAGE_GREY_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 0;
    height: auto;
  }
`

const PageHeaderH2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
  max-width: 50%;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 21px;
    margin: 30px 0;
    max-width: none;
  }
`

const BulletUlWrapper = styled.div`
  background-color: ${theme.colors.grey.greyBackground};
  padding: 32px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & ul {
    width: min(1140px, calc(100vw - 32px));
    margin-bottom: 0;
  }
`

const Flexbox = styled.div`
  display: flex;

  margin: 0px 0px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`
const CwfCol1 = styled.div`
  flex: 0 0 200px;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.825rem;

  height: 100%;

  background: ${props => props.theme.colors.primary.blueLightest};
  color: ${props => props.theme.colors.primary.blueDark};

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`

const CwfCol1Header = styled(CwfCol1)`
  padding: 0.5rem 0.825rem;
  background: white;
`

const CwfCol2 = styled.div`
  flex: 1 0;
  align-items: flex-end;

  padding: 12px 1.5rem;

  > div.border {
    padding-bottom: 12px;
    height: 100%;
    border-bottom: solid 1px #c5c5c5;
  }

  border-right: solid 1px #c5c5c5;
  &:last-child {
    border-right: none;
  }
`

const ProgressFlex = styled(CwfCol2)`
  padding: 12px 0;
`

const CwfFlexbox = styled.div`
  display: flex;
  height: 60px;
  margin-left: 8px;
`

const BulletUl = styled.ul`
  list-style-image: url(${BulletGreen});

  margin: 0;
  padding-inline-start: 4px;
  margin-block-end: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  > li {
    padding-left: 12px;
  }

  > li:not(:last-child) {
    margin-bottom: 1em;
  }
`

const PageBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 28px 0;
`

const ProgressionChartWrapper = styled.div`
  width: min(1140px, calc(100vw - 32px));
`

const Page = props => {
  const { pageContext, location } = props
  const SHOW_COLUMNS = 3
  const QUERY_STRING = "code"

  const headerList = pageContext.bands[0].headers.map(h => h.header)
  const headerListLastIndex = headerList.length - 1

  React.useEffect(() => {
    if (window.innerWidth < 1023) {
      const redirect = getBandDetailsUrlFromComparisonUrl(window.location.href)
      navigate(redirect)
    }
  })

  const initIndexLevel = (bands, bandsCode) => {
    if (typeof bandsCode === "undefined") {
      return 0
    }

    const bandsOrder = bands.map(j => j[QUERY_STRING])
    const tmpIndex = bandsOrder.indexOf(bandsCode)
    return tmpIndex + SHOW_COLUMNS > bandsOrder.length
      ? bandsOrder.length - SHOW_COLUMNS
      : tmpIndex
  }

  const [indexLevel, setIndexLevel] = React.useState(
    initIndexLevel(
      pageContext.bands,
      queryString.parse(location.search)[QUERY_STRING]
    )
  )

  const isShowDetails = index =>
    index >= indexLevel && index < indexLevel + SHOW_COLUMNS

  const renderHeaderRow = (header, bands, isLast = false) => {
    const renderBandContent = (band, bandIndex) => {
      const foundHeader = band.headers.find(h => h.header === header)

      return (
        <CwfCol2 key={`${kebabCase(foundHeader)}-${bandIndex}`}>
          <div className={isLast ? "" : "border"}>
            <BulletUl>
              {foundHeader.content.map((c, bulletIndex) => (
                <li
                  key={`${kebabCase(foundHeader)}-${bandIndex}-${bulletIndex}`}
                >
                  {c}
                </li>
              ))}
            </BulletUl>
          </div>
        </CwfCol2>
      )
    }

    return (
      <Flexbox key={`cb-${kebabCase(header)}`}>
        <CwfCol1>{header}</CwfCol1>
        {bands.map(
          (band, index) =>
            isShowDetails(index) && renderBandContent(band, index)
        )}
      </Flexbox>
    )
  }

  return (
    <AppLayout>
      <PageContent>
        <PageHeader>
          <PageHeaderBoxes>
            <PageHeaderGrey />
            <PageHeaderBlocker />
          </PageHeaderBoxes>
          <PageHeaderTextContainer>
            <PageHeaderH2>{pageContext.name}</PageHeaderH2>
          </PageHeaderTextContainer>
        </PageHeader>
        <DesktopContent>
          <BulletUlWrapper>
            <BulletUl>
              {pageContext.descriptions.map((description, idx) => (
                <li key={`desc-${idx}`}>{description}</li>
              ))}
            </BulletUl>
          </BulletUlWrapper>
        </DesktopContent>
        <PageBody>
          <ProgressionChartWrapper>
            <Flexbox>
              <CwfCol1Header></CwfCol1Header>
              <ProgressFlex>
                <CwfFlexbox>
                  {indexLevel !== 0 && (
                    <ProgressPrevNext
                      onClickFn={() => setIndexLevel(indexLevel - 1)}
                      tileArrowSvgComponent={tileArrowNext}
                      filterId="progressBack"
                      colorHex={pageContext.bgColor}
                      isPrev
                    />
                  )}
                  {pageContext.bands.map(
                    (band, index) =>
                      isShowDetails(index) && (
                        <ProgressArrowBox
                          key={band.code}
                          text={band.code}
                          bgColor={pageContext.bgColor}
                          textColor={pageContext.textColor}
                          filterId={`progress-arrow-box-filter-${index}`}
                        />
                      )
                  )}
                  {indexLevel + SHOW_COLUMNS < pageContext.bands.length && (
                    <ProgressPrevNext
                      onClickFn={() => setIndexLevel(indexLevel + 1)}
                      tileArrowSvgComponent={tileArrowNext}
                      filterId="progressNext"
                      colorHex={pageContext.bgColor}
                    />
                  )}
                </CwfFlexbox>
              </ProgressFlex>
            </Flexbox>

            {headerList.map((header, headerIndex) =>
              renderHeaderRow(
                header,
                pageContext.bands,
                headerIndex === headerListLastIndex
              )
            )}
          </ProgressionChartWrapper>
        </PageBody>
      </PageContent>
    </AppLayout>
  )
}

export default Page
