import hexRgb from "hex-rgb"

export default {
  hexToMatrix: colorHex => {
    const { red, green, blue, alpha } = hexRgb(colorHex)

    // Ref: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feColorMatrix
    return `
      0 0 0 0 ${red / 255}
      0 0 0 0 ${green / 255}
      0 0 0 0 ${blue / 255}
      0 0 0 ${alpha} 0
    `
  },
  hexToMatrixWithAlpha: (colorHex, alpha) => {
    const { red, green, blue } = hexRgb(colorHex)

    // Ref: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feColorMatrix
    return `
      0 0 0 0 ${red / 255}
      0 0 0 0 ${green / 255}
      0 0 0 0 ${blue / 255}
      0 0 0 ${alpha} 0
    `
  },
}
